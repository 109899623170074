@import 'variables.scss';
@import 'mixins.scss';

//Define colors
$c--login-background: $color-1;
$c--login-primary: $color-white;
$c--login-link-opacity: 0.8;
$c--login-success: $color-5;
$c--login-error: $color-2;
$fw--bold: $font-weight-bold;

body {
	color: $c--login-primary;
	background: $c--login-background;
	line-height: 1.3;
}

#login {
	width: 320px;

	@include responsive(mobile) {
		width: 360px;
	}
}

a {
	color: $c--login-primary;
	@include transition;

	&:hover {
		color: $c--login-primary;
		opacity: $c--login-link-opacity;
	}
}

.login {
	h1 {
		a {
			height: 100px !important;
			width: 100px !important;
			background-size: 100px !important;
		}
	}

	form {
		border: none;
		box-shadow: none;
		background: transparent;
	}

	label {
		color: $c--login-primary;
		font-size: 12px;
	}

	#login_error {
		border: none;
		background: transparent;
		color: $c--login-primary;
		box-shadow: none;
		text-align: center;
	}

	.message {
		border: none;
		background: transparent;
		color: $c--login-primary;
		box-shadow: none;
		text-align: center;
	}
}

#nav,
#backtoblog {
	display: none;
}

.login input[type='text'],
.login input[type='password'],
#login input[type='tel'] {
	background: transparent;
	border: none;
	border-bottom: 1px solid $c--login-primary;
	color: $c--login-primary;
	box-shadow: none;
	font-weight: normal;
	font-family: 'Courier', 'Courier New', sans-serif;

	&:focus {
		outline: none;
		box-shadow: none;
	}
}

input[type='checkbox'] {
	border: 1px solid $c--login-background;
	background: $c--login-background;

	&:checked:before {
		color: $c--login-background;
	}

	&:focus {
		border: 1px solid $c--login-background;
		box-shadow: none;
	}
}

#wp-submit,
#submit {
	margin-top: 20px;
	width: 100%;
}

.wp-core-ui .button {
	background: transparent;
	color: $c--login-primary;
	border: 1px solid $c--login-primary;
	border-radius: 4px;
	text-shadow: none;
	font-size: 12px;
	box-shadow: none;
	text-transform: uppercase;
	font-weight: $fw--bold;
	@include transition();

	&:hover,
	&:active,
	&:focus {
		color: $c--login-background;
		background: $c--login-primary;
		border-color: $c--login-primary;
		box-shadow: none;
		transform: none;
	}

	&-primary {
		&:disabled,
		&[disabled] {
			opacity: 0.5;
			cursor: not-allowed;
			background: $c--login-primary !important;
			color: $c--login-background !important;
			border: 1px solid $c--login-primary !important;

			&:hover {
				background: $c--login-primary !important;
				color: $c--login-background !important;
				border: 1px solid $c--login-primary !important;
			}
		}
	}

	&.itsec-totp__device-switcher-button--android,
	&.itsec-totp__device-switcher-button--ios {
		text-transform: none;

		&.button-primary {
			background: $c--login-primary;
			color: $c--login-background;
		}
	}

	&-link {
		color: $c--login-primary;
		@include transition;

		&:hover {
			color: $c--login-primary;
			opacity: $c--login-link-opacity;
		}
	}
}

.wp-core-ui .button-group.button-large .button,
.wp-core-ui .button.button-large {
	height: 40px;
}

a:focus {
	box-shadow: none;
}

.itsec-backup-methods {
	border: none !important;
	display: none !important;

	ul {
		list-style: none !important;
		padding: 0;
		margin: 0 !important;
	}

	a {
		color: $c--login-primary;
		text-decoration: none !important;
	}
}

.itsec-backup-codes__code-list {
	font-family: 'Courier', 'Courier New', sans-serif;

	li {
		color: $c--login-primary !important;
	}
}

.itsec-notice {
	background-color: transparent !important;
	box-shadow: none !important;
	color: $c--login-primary;
	border: none !important;

	.button-link {
		font-weight: $fw--bold;
	}
}

form[name='validate_2fa_form'] {
	.button {
		line-height: 36px;
		height: 38px;
	}
}

#itsec-2fa-on-board .itsec-screen__actions--cancel {
	color: $c--login-primary !important;
	@include transition();

	&:hover {
		opacity: $c--login-link-opacity;
	}
}

.itsec-provider__status--disabled {
	background-color: $c--login-error !important;
}

.itsec-provider__status--enabled {
	background-color: $c--login-success !important;
}

.itsec-screen--providers h2 + p {
	border-bottom: 1px solid rgba($c--login-primary, 0.3) !important;
}

#language-switcher {
	display: none !important;
}
