$color-main: #5A5A5A;
$color-main-light: #ACACAC;
$color-background: #FCFCFC;
$font-weight-normal: 400;
$font-weight-bold: 600;
$width-mobile-small: 375px; //necesaria?
$width-mobile: 480px;
$width-mobile-landscape: 700px; //necesaria?
$width-tablet-portrait: 768px;
$width-tablet-landscape: 1024px;
$maxL: $width-tablet-portrait - 1px;
$width-desktop: 1300px;
$width-menu-change: 1110px;
$width-logo-change: 900px;
$color-1: #FFA66B;
$color-2: #FA7575;
$color-3: #CC68E3;
$color-4: #7CBEEA;
$color-5: #5BD9CA;
$color-1-tint: #E68F55;
$color-2-tint: #E05E5E;
$color-3-tint: #B453C9;
$color-4-tint: #64A6D1;
$color-5-tint: #47BFB1;
$color-1-cal: #CC7941;
$color-2-cal: #C74A4A;
$color-3-cal: #9B3FB0;
$color-4-cal: #4F8EB8;
$color-5-cal: #35A699;
$color-main-tint: #7b7b7b;
$color-white: #ffffff;
$color-white-bg: rgba(255,255,255,0.9);
$color-table-border: #f0f0f0;
$margin-top-sections-desktop: 5rem;
$margin-top-sections-mobile: 4rem;
$transition: 0.2s;












// fonts

$primary-font: sans-serif;

// header size

$header-height-S: 60px;
$header-height-L: 60px;

// Navigation size

$nav-width-S: 260px;

// Search size

$search-height-S: 50px;
$search-height-L: 120px;

// z-index

$below-content: 1;
$content: 2;
$above-content: 3;
