@mixin responsive ($width) {
	@if $width == mobile {
		@media only screen and (min-width: $width-mobile) {
			@content;
		}
	}
	@else if $width == mobilebig {
		@media only screen and (min-width: $width-mobile-small) {
			@content;
		}
	}
	@else if $width == mobilelandscape {
		@media only screen and (min-width: $width-mobile-landscape) {
			@content;
		}
	}
	@else if $width == tablet {
		@media only screen and (min-width: $width-tablet-portrait) {
			@content;
		}
	}
	@else if $width == desktop {
		@media only screen and (min-width: $width-tablet-landscape) {
			@content;
		}
	}
	@else if $width == widedesktop {
		@media only screen and (min-width: $width-desktop) {
			@content;
		}
	}
	@else if $width == logo {
		@media only screen and (min-width: $width-logo-change) {
			@content;
		}
	}

	@else if $width == menu {
		@media only screen and (min-width: $width-menu-change) {
			@content;
		}
	}

	@else if $width == maxL {
		@media only screen and (max-width: $maxL) {
			@content;
		}
	}
}

// super light grid - it works with the .cd-container class inside style.scss
@mixin column($percentage, $float-direction:left) {
  width: 100% * $percentage;
  float: $float-direction;
}

@mixin borderbox() {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

@mixin ease-animation {
	-webkit-transition: all 200ms cubic-bezier(0.250, 0.100, 0.250, 1.000);
	-moz-transition: all 200ms cubic-bezier(0.250, 0.100, 0.250, 1.000);
	-o-transition: all 200ms cubic-bezier(0.250, 0.100, 0.250, 1.000);
	transition: all 200ms cubic-bezier(0.250, 0.100, 0.250, 1.000); /* ease (default) */

}

@mixin transition($time: $transition) {
    transition: all $time ease;
}

@mixin clearfix {
	&::after {
	  clear: both;
	  content: "";
	  display: block;
	}
  }
